import React from "react"
import "./board.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Logo from "../images/board-logo.svg"
import CTA from "../components/cta"

const Board = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <div styleName="hero">
      <div styleName="heroFlex">
        <div styleName="flexCenter">
          <h1>Board and Advisors</h1>

          <p>
            A world class service needs world class leadership. In addition to
            our Founders Dr John Lang and Ali Khan, SHAPE is supported by
            business and academic experts at the top of their game.{" "}
          </p>
        </div>
        <div>
          <img src={Logo} alt="SHAPE AI" />
        </div>
      </div>
    </div>
    <div styleName="content">
      <div styleName="text">
        <div styleName="card">
          <Img
            fixed={data.hardeep.childImageSharp.fixed}
            alt="Dr. Hardeep Tamana"
          />
          <h3>Dr. Hardeep Tamana</h3>
          <span>Board Member</span>
          <p>
            Hardeep Tamana, PhD has over twenty five years of financial markets
            experience, having worked with a number of the largest banking and
            stockbroking firms. Holding a PhD in geochemistry from Manchester
            University and has also developed a number of next-generation tools
            for financial services. This includes proprietary share screening
            software and complex securities registry systems. Hardeep served for
            eight years as CEO of a UK stockbroking firm and is currently the MD
            of Ireland & UK Share Registrars, bringing a wide range of
            leadership, technical and markets expertise to SHAPE.
          </p>
        </div>
        <div styleName="card">
          <Img
            fixed={data.aneel.childImageSharp.fixed}
            alt="Dr. Aneel Cheema"
          />
          <h3>Dr. Aneel Cheema</h3>
          <span>Advisory Board</span>
          <p>
            Aneel Chima, PhD directs the Division of Health and Human
            Performance and founding director of the Stanford Flourishing
            Project at Stanford University. His approach harnesses powerful
            insights from the emerging science of wellbeing and human
            flourishing and applies them to solve tactical and strategic
            challenges confronting organisations in an ever more dynamic,
            asymmetrical world. Passionate about using psychological insights to
            affect sustainable social change, Aneel currently serves as Chair of
            the Board of Trustees of the California Institute of Integral
            Studies (CIIS) and a board member of Consciousness Hacking, a global
            community exploring technology as a catalyst for human connection
            and wellbeing. He has published in the Harvard Business Review, the
            Stanford Social Innovation Review, among other publications.
          </p>
        </div>
        <div styleName="card">
          <Img
            fixed={data.richard.childImageSharp.fixed}
            alt="Dr. Richard Siow"
          />
          <h3>Dr. Richard Siow</h3>
          <span>Advisory Board</span>
          <p>
            Richard Siow, PhD is Director of Ageing Research at King’s College
            London (KCL) and obtained his BSc and PhD at KCL before postdoctoral
            research at the University of Cambridge. He has been an academic
            member of Faculty of Life Sciences & Medicine at KCL since 2002 and
            was previously the Faculty Vice-Dean (International). Richard had a
            sabbatical period with Unilever as an advisor to their Strategic
            Science Group and championed establishment of the Unilever
            Bioscience Innovation Hub at KCL. Richard’s research focuses on
            nutrigenomics and ageing. He is an elected Fellow of the Royal
            Society of Biology, member of the Strategic Advisory Board of the UK
            All Party Parliamentary Group for Longevity and International
            Advisory Board of The Lancet Healthy Longevity.
          </p>
        </div>
        <div styleName="card">
          <Img
            fixed={data.ian.childImageSharp.fixed}
            alt="Dr. Hardeep Tamana"
          />
          <h3>Dr. Ian Shadforth</h3>
          <span>Advisory Board</span>
          <p>
            Ian Shadforth, MA, MBA, EngD is a Cambridge-educated scientist with
            a doctorate in bioinformatics from Cranfield University. He has a
            wealth of experience in the science, strategy, and development of
            connected health and technology ecosystems. Ian has been awarded an
            Enterprise Fellowship by the Royal Society of Edinburgh and the
            Emerging Chemometrician prize by the Royal Society of Chemistry. He
            is co-author of the textbook Building Bioinformatics Solutions
            (Oxford University Press), has authored a number of peer-reviewed
            scientific publications, and holds patents in the areas of machine
            learning, diagnostics, and disease management.
          </p>
        </div>
      </div>
    </div>
    <CTA isFreeReport={true} />
  </Layout>
)

export default Board

export const query = graphql`
  query {
    richard: file(relativePath: { eq: "drRichardImg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    aneel: file(relativePath: { eq: "drAneelImg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    hardeep: file(relativePath: { eq: "drHardeepImg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    ian: file(relativePath: { eq: "drIanImg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
